import { Popover, Row, Col, Tooltip, Button } from "antd";
import { useEffect, useState } from "react";
import { useStateValue } from "../../../util/StateProvider";
import { InputAdornment, TextField } from "@mui/material";

import { DatePicker, Space } from "antd";
import {
  ArrowRightOutlined,
  CalendarOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  FaDownload,
  FaRedo,
  FaPlay,
  FaRedoAlt,
  FaQuestionCircle,
} from "react-icons/fa";
import { BiReset } from "react-icons/bi";
import moment from "moment";
import { Loader } from "svg-loader-react";
import "./Filters.scss";
import AdvancedFilter from "../../../common/components/advancedFilter/AdvancedFilter";

import MenuItem from "@mui/material/MenuItem";
import React from "react";
import SelectFilter from "../../../common/components/normalFilter/SelectFilter";
import { GetFilters } from "../../../services/filtersDrop";
import { endOfWeek, format, parse, startOfWeek } from "date-fns";
const { RangePicker } = DatePicker;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      fontSize: 16,
    },
  },
};

interface investigatorDetails {
  InvestigatorID: string;
  Investigator_FirstName: string;
  Investigator_LastName: string;
}
const Filters = () => {
  const [mssidFilter, setMssidFilter] = useState(false);
  const [openRange, setopenRange] = useState(false);
  const [startTime, setStartTime] = useState(
    moment().subtract(7, "d").format("MM/DD/YYYY")
  );
  const [EndTime, setEndTime] = useState(moment().format("MM/DD/YYYY"));
  const [patientNameFilter, setpatientNameFilter] = useState(false);
  const [InvestigatorDetails, setInvestigatorDetails] = React.useState<any>();
  const [investigatorNames, setinvestigatorNames] = React.useState<any>([]);
  const [InvestigatorFilter, setInvestigatorFilter] = useState(false);
  const [JurisdictionFilter, setJurisdictionFilter] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filterLoaded, setFliterLoaded] = useState(false);
  const [allfilterdata, setallfilterdata] = useState({});
  const [getdata, dispatch] = useStateValue();
  useEffect(() => {
    GetFilters().then((res) => {
      setallfilterdata(res);
      setFliterLoaded(true);
      let optns = res?.InvestigatorDetails?.map((a: investigatorDetails) => {
        return {
          ID: a.InvestigatorID,
          Value: a.Investigator_FirstName + a.Investigator_LastName,
          ...a,
        };
      });
      setInvestigatorDetails(optns);
    });
  }, []);
  useEffect(() => {
    if (getdata.TableInvestigatorFilter?.length > 0) {
      setLoaded(true);
    }
  }, [getdata.TableInvestigatorFilter]);

  // const closemssidFilter = () => {
  //   setMssidFilter(false);
  // };
  // const openmssidFilter = () => {
  //   setMssidFilter(true);
  // };
  // const closepatientNameFilter = () => {
  //   setpatientNameFilter(false);
  // };
  // const openpatientNameFilter = () => {
  //   setpatientNameFilter(true);
  // };
  // const closeInvestigatorFilter = () => {
  //   setInvestigatorFilter(false);
  // };
  // const openInvestigatorFilter = () => {
  //   setInvestigatorFilter(true);
  // };
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setinvestigatorNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div className="FilteMain">
      <Row gutter={25} className="row">
        <Col className="fields" span={4}>
          <Popover
            content={
              filterLoaded ? (
                <SelectFilter
                  Stateid="mssidFilter"
                  allFilterData={allfilterdata}
                />
              ) : (
                <Loader name={"audio"} color={"#fdb933"} />
              )
            }
            trigger="click"
            visible={mssidFilter}
            placement={loaded ? "bottomLeft" : "bottom"}
            style={{ left: "5px" }}
            onVisibleChange={() => {
              setMssidFilter(!mssidFilter);
            }}
          >
            <TextField
              size="small"
              id="outlined-read-only-input"
              label="MDSS ID"
              fullWidth
              inputProps={{
                style: {
                  textOverflow: "ellipsis",
                  cursor: loaded ? "text" : "progress",
                },
              }}
              onClick={(e) => e.stopPropagation()}
              value={getdata.mdssIdFilter.join(" or ")}
              InputProps={{
                readOnly: true,
              }}
            >
              <MenuItem value={"is any value"}>is any value</MenuItem>
            </TextField>
          </Popover>
        </Col>
        <Col className="fields" span={4}>
          <Popover
            content={
              filterLoaded ? (
                <SelectFilter
                  Stateid="patientNameFilter"
                  allFilterData={allfilterdata}
                />
              ) : (
                <Loader name={"audio"} color={"#fdb933"} />
              )
            }
            placement={loaded ? "bottomLeft" : "bottom"}
            trigger="click"
            visible={patientNameFilter}
            onVisibleChange={() => {
              setpatientNameFilter(!patientNameFilter);
            }}
          >
            <TextField
              size="small"
              id="outlined-read-only-input"
              label="Patient Name"
              fullWidth
              inputProps={{
                style: { textOverflow: "ellipsis" },
                cursor: loaded ? "text" : "progress",
              }}
              value={getdata.patientNameFilter.join(" or ")}
              InputProps={{
                readOnly: true,
              }}
            ></TextField>
          </Popover>
        </Col>
        <Col className="fields" span={4}>
          <Popover
            content={
              loaded ? (
                <SelectFilter
                  Stateid="InvestigatorFilter"
                  allFilterData={allfilterdata}
                />
              ) : (
                <Loader name={"audio"} color={"#fdb933"} />
              )
            }
            placement={loaded ? "bottomLeft" : "bottom"}
            getPopupContainer={(container) => container}
            trigger="click"
            visible={InvestigatorFilter}
            onVisibleChange={() => {
              setInvestigatorFilter(!InvestigatorFilter);
            }}
          >
            <TextField
              size="small"
              id="outlined-read-only-input"
              label="Investigator"
              fullWidth
              inputProps={{
                style: { textOverflow: "ellipsis" },
                cursor: loaded ? "text" : "progress",
              }}
              onClick={() => {
                console.log(getdata.investigatorFilter);
              }}
              value={getdata.investigatorFilter.toString().replace(",", " or ")}
              InputProps={{
                readOnly: true,
              }}
            ></TextField>
          </Popover>
        </Col>
        <Col
          className="fields"
          style={{ padding: "0", justifyContent: "flex-start" }}
          span={2}
        >
          {/* <Tooltip title="Clear clipboard">
            <BsClipboardX
              color="#00b2b9"
              onClick={async() => {
               let text= await navigator.clipboard.readText();
               text = text.replace(/\n/g, " ");
               console.log(text);
               navigator.clipboard.writeText(text)
              }}
              style={{ cursor: "pointer" }}
              size={24}
            />
          </Tooltip> */}
        </Col>

        <Col className="Date" span={5}>
        <Popover
            onVisibleChange={(e) => {
              setopenRange(!openRange);
              if (!e) {
                dispatch({
                  type: "SetFormDateValues",
                  formDate: [
                    moment(startTime, "MM/DD/YYYY").format("YYYY-MM-DD"),
                    moment(EndTime, "MM/DD/YYYY").format("YYYY-MM-DD"),
                  ],
                });
                dispatch({
                  type: "SETRESET",
                  reset: false,
                });
              }
            }}
            content={
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  "Past 3 days": [moment().subtract(3, "days"), moment()],
                  "Past 7 days": [moment().subtract(7, "days"), moment()],
                  "Past 14 days": [moment().subtract(14, "days"), moment()],
                  "Past 30 days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onOpenChange={(e) => {
                  if (!e) {
                    dispatch({
                      type: "SETRESET",
                      reset: false,
                    });
                  }
                }}
                clearIcon={null}
                separator={<ArrowRightOutlined style={{ color: "#828282" }} />}
                size="small"
                value={[
                  moment(startTime, "MM/DD/YYYY"),
                  moment(EndTime, "MM/DD/YYYY"),
                ]}
                onChange={(time: any) => {
                  dispatch({
                    type: "SETRESET",
                    reset: false,
                  });
                  setStartTime(time[0].format("MM-DD-YYYY"));
                  setEndTime(time[1].format("MM-DD-YYYY"));
                  dispatch({
                    type: "SetFormDateValues",
                    formDate: [
                      time[0].format("YYYY-MM-DD"),
                      time[1].format("YYYY-MM-DD"),
                    ],
                  });
                }}
                format={"MM-DD-YYYY"}
              />
            }
            placement="bottom"
            trigger="click"
          >
            <TextField
              size="small"
              id="outlined-read-only-input"
              label={`Form Sent Date ` + "\xa0 \xa0"}
              value={getdata.reset ? "" : `${startTime} - ${EndTime}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarOutlined />
                  </InputAdornment>
                ),
                readOnly: true,
                style: { width: `${245}px`, fontSize: "14px" },
              }}
            />
          </Popover>
        </Col>
        <Col className="fields" span={5}>
          <div className="IconsFilter">
            <Button
              onClick={() => {
                if (getdata.reset) {
                  dispatch({
                    type: "SetSearchQuerry",
                    searchQuerry: {},
                  });
                } else {
                  dispatch({
                    type: "SetSearchQuerry",
                    searchQuerry: {
                      ...getdata.searchQuerry,
                      Form_Sent_Date: [
                        moment(startTime, "MM/DD/YYYY").format("YYYY-MM-DD"),
                        moment(EndTime, "MM/DD/YYYY").format("YYYY-MM-DD"),
                      ],
                    },
                  });
                }

                dispatch({
                  type: "SetApplyFilter",
                  applyFilter: !getdata.applyFilter,
                });

                console.log("Apply");
                console.log("Apply");
              }}
              className="tabButtons"
            >
              Apply Filter
            </Button>
            <Button
              onClick={() => {
                setStartTime(format(startOfWeek(new Date()), "MM/dd/yyyy"));
                setEndTime(format(endOfWeek(new Date()), "MM/dd/yyyy"));
                dispatch({
                  type: "SETRESET",
                  reset: true,
                });
                dispatch({
                  type: "SetSearchQuerry",
                  searchQuerry: {},
                });
                dispatch({
                  type: "SetFormDateValues",
                  formDate: [],
                });
                dispatch({
                  type: "SetApplyFilter",
                  applyFilter: !getdata.applyFilter,
                });
              }}
              className="tabButtons"
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
