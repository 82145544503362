import "./App.scss";
import "antd/dist/antd.css";
import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useStateValue } from "./util/StateProvider.jsx";
import Dashboard from "./components/dashboard/Dashboard";
import { CheckLogin, checkToken } from "./services/login";
import FourOFour from "./components/404/404";
import "react-toastify/dist/ReactToastify.css";
import Form from "./components/form/Form";
import PatientAuthForm from "./components/form/PatientAuthForm";
import PatientForm from "./components/form/PatientForm";

function useQuery() {
  const { search } = useLocation();

  return new URLSearchParams(search);
}
function isAllowedJurisdiction(jurisdiction: string) {
  let allowedJurisdictions = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "10",
    "11",
    "12",
    "13",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "24",
    "26",
    "36",
    "44",
    "45",
  ];
  console.log(allowedJurisdictions.includes(jurisdiction));
  return allowedJurisdictions.includes(jurisdiction);
}
function App() {
  let navigate = useNavigate();
  const query = useQuery();
  const [state, setState] = useStateValue();

  useEffect(() => {
    // localStorage.clear();
    // if url is not /edit/form then check if token is present in local storage
    if (
      window.location.pathname !== "/edit/form" &&
      window.location.pathname !== "/verify"
    ) {
      let URLtoken: any = query.get("token") || localStorage.getItem("token");
      CheckLogin(URLtoken)
        .then((res) => {
          if (
            res.statusCode == 200 &&
            res.token &&
            res.status === true
            // && isAllowedJurisdiction(res.data.Jurisdiction_Code)
          ) {
            setState({
              type: "SETTOKEN",
              token: res.token,
            });
            setState({
              type: "SETUSER",
              currentUser: res.data,
            });
            setState({
              type: "SETAUTH",
              authenticted: true,
            });
            setState({
              type: "SETUSERDATA",
              userData: res,
            });
            navigate("/home");
          } else {
            throw new Error("failed");
          }
        })
        .catch((e) => {
          setState({
            type: "SETSPIN",
            spin: false,
          });
        });
    }
  }, []);

  useEffect(() => {
    console.log(state.token, state.authenticted, "consoleeee");
  }, [state.token, state.authenticted]);

  return (
    <div className="App" style={{ width: "100%", height: 300 }}>
      {state.token !== null && state.authenticted ? (
        <Routes>
          <Route path={"/home"} element={<Dashboard />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={"/edit/form"} element={<Form />} />
          <Route path={"/verify"} element={<PatientAuthForm />} />
          <Route path={"/form"} element={<PatientForm />} />
          <Route path={"*"} element={<FourOFour />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
