import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authenticate } from "../../services/patientAuthForm";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import { log } from "console";

interface FormInputs {
  lastName?: string;
  birthMonth?: string;
  birthDay?: string;
  token?: string; // Token state added here
}

const validMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function PatientAuthForm() {
  const [formData, setFormData] = useState<FormInputs>({});
  const [paramsString, setParamsString] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [searchParams] = useSearchParams();
  const [paramsAdded, setParamsAdded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedToken = urlParams.get("token");
    if (encryptedToken) {
      try {
        const decodedToken = atob(encryptedToken); // Decode from base64
        setFormData((values) => ({ ...values, token: decodedToken })); // Set token in formData
        urlParams.delete("token");
        const newQueryString = urlParams.toString();
        const newUrl = newQueryString
          ? `${location.pathname}?${newQueryString}`
          : location.pathname;
        window.history.replaceState(null, "", newUrl);
      } catch (error) {
        console.error("Error decoding the token:", error);
      }
    }
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrors({}); // Reset errors before validation

    const validationErrors: { [key: string]: string } = {};

    // Validate Last Name
    if (!formData.lastName || formData.lastName.trim() === "") {
      validationErrors.lastName = "Last name is required";
    }

    // Validate Birth Month
    if (!formData.birthMonth || !validMonths.includes(formData.birthMonth)) {
      validationErrors.birthMonth = "Please select month";
    }

    // Validate Birth Day
    if (!formData.birthDay) {
      validationErrors.birthDay = "Birth day is required";
    } else if (
      isNaN(Number(formData.birthDay)) ||
      Number(formData.birthDay) < 1 ||
      Number(formData.birthDay) > 31
    ) {
      validationErrors.birthDay = "Birth day must be a number between 1 and 31";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log(formData.token, "Form data before API call");

      if (!formData.token) {
        toast("Token is missing or invalid.", {
          position: "top-right",
          theme: "dark",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Don't proceed with the API call if token is missing
      }

      // API call
      authenticate(formData)
        .then((res) => {
          const { jotformParams } = res.data.data;

          setParamsString(jotformParams ? `${jotformParams}` : "");
          setParamsAdded(true);

          toast("Form submitted successfully.", {
            position: "top-right",
            theme: "dark",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((e) => {
          toast("Failed to send Jot Form link", {
            position: "top-right",
            theme: "dark",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  if (!paramsAdded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f0f2f5",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "30px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <h2
            style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}
          >
            Patient Verification
          </h2>
          <form onSubmit={handleSubmit}>
            <label
              style={{ display: "block", marginBottom: "10px", color: "#555" }}
            >
              Last Name <span style={{ color: "red" }}>*</span>
              <input
                type="text"
                name="lastName"
                value={formData.lastName || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: errors.lastName ? "1px solid red" : "1px solid #ccc",
                  fontSize: "16px",
                  color: "#333",
                }}
              />
              {errors.lastName && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.lastName}
                </span>
              )}
            </label>

            <label
              style={{ display: "block", marginBottom: "10px", color: "#555" }}
            >
              Birth Month <span style={{ color: "red" }}>*</span>
              <select
                name="birthMonth"
                value={formData.birthMonth || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "13px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: errors.birthMonth
                    ? "1px solid red"
                    : "1px solid #ccc",
                  fontSize: "16px",
                  color: "#333",
                }}
              >
                <option value="">Select Month</option>
                {validMonths.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              {errors.birthMonth && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.birthMonth}
                </span>
              )}
            </label>

            <label
              style={{ display: "block", marginBottom: "10px", color: "#555" }}
            >
              Birth Day <span style={{ color: "red" }}>*</span>
              <select
                name="birthDay"
                value={formData.birthDay || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "13px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: errors.birthDay ? "1px solid red" : "1px solid #ccc",
                  fontSize: "16px",
                  color: "#333",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <option value="">Select Day</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={day.toString().padStart(2, "0")}>
                    {day}
                  </option>
                ))}
              </select>
              {errors.birthDay && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.birthDay}
                </span>
              )}
            </label>

            <button
              type="submit"
              style={{
                width: "100%",
                padding: "12px",
                backgroundColor: "#007BFF",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                fontSize: "16px",
                cursor: "pointer",
                marginTop: "20px",
              }}
            >
              Submit
            </button>
          </form>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          toastClassName="fontMon"
          pauseOnHover
        />
      </div>
    );
  }

  return (
    <iframe
      src={`/jotform.html${paramsString}`}
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Patient Education Form"
    ></iframe>
  );
}

export default PatientAuthForm;
