import axios from "axios";

export async function authenticate(data: any): Promise<any> {
    console.log(localStorage.getItem("token"),"authenticate--------")
  var config = {
    method: "post",
    url: "/getJotform",
    headers: {
      // Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  console.log(config,"config")
  try {
    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
