import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
import {
  Popover,
  Table,
  Input,
  Form,
  Pagination,
  Select,
  Tooltip,
  Button,
  Popconfirm,
} from "antd";
import _ from "lodash";
import { Modal } from "antd";
import moment from "moment";
import {
  FaRegCopy,
  FaExternalLinkAlt,
  FaEdit,
  FaFilter,
  FaSpinner,
} from "react-icons/fa";
import "./AntTable.scss";
import { useStateValue } from "../../../../util/StateProvider";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import { getAllTableData } from "../../../../services/tableFind";
import { Loader } from "svg-loader-react";
import type {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import { sendEducationLinkApi } from "../../../../services/educationLink";
import { sendJotFormLink } from "../../../../services/jotFormLink";
import { ManagedInMdssApi } from "../../../../services/managedInMdss";
import { GetFilters } from "../../../../services/filtersDrop";
import { ChangeBulkInvestigator } from "../../../../services/investigator";
import { changeNumber } from "../../../../services/changeNumber";
import { useVT } from "virtualizedtableforantd4";
import { useNavigate } from "react-router-dom";

interface RefObject<T> {
  readonly current: T | null;
}
const EditableContext = React.createContext<any | null>(null);

const { Option } = Select;
const EditableRow = React.forwardRef((props, ref: any) => {
  const { children, ...rest } = props;
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...rest} ref={ref}>
          {children}
        </tr>
      </EditableContext.Provider>
    </Form>
  );
});
// const EditableRow: React.forwardRef({ index, ...props }) => {
//   const [form] = Form.useForm();
//   return (
//     <Form form={form} component={false}>
//       <EditableContext.Provider value={form}>
//         <tr {...props} />
//       </EditableContext.Provider>
//     </Form>
//   );
// };

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: any) => {
  const [editing, setEditing] = useState(false);
  const [phoneFields, SetPhoneFields] = useState<any[]>();
  const inputRef = useRef<any>(null);
  const form = useContext<any>(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    console.log("focused");
    if (record[dataIndex]) {
      let numbersArray = record[dataIndex]?.split(";");
      let numberFieldsObject: any = {};
      let numberOfFields: any[] = [];
      for (let i = 0; i < numbersArray.length; i++) {
        if (numbersArray[i] !== "null") {
          numberFieldsObject[(dataIndex + i) as string] = numbersArray[i];
          numberOfFields.push(dataIndex + i);
        } else {
          numberFieldsObject[(dataIndex + i) as string] = "";
          numberOfFields.push(dataIndex + i);
        }
      }
      SetPhoneFields(numberOfFields);
      form.setFieldsValue(numberFieldsObject);
    } else {
      SetPhoneFields([0]);
      form.setFieldsValue(0);
    }
    setEditing(!editing);
  };
  const validatePhone = (rule: any, value: any, callback: any) => {
    if (value) {
      if (isValidPhoneNumber(value, "US")) callback();
      else {
        callback(`Invalid US Number`);
      }
    } else {
      callback();
    }
  };
  const save = async () => {
    try {
      const values = await form.validateFields();

      let PhoneString = "";
      let ValueObject: any = {};
      for (let index = 0; index < phoneFields!.length; index++) {
        PhoneString = PhoneString + values[phoneFields![index]] + ";";
      }
      PhoneString = PhoneString.slice(0, -1);

      ValueObject = {
        Phone: PhoneString,
      };
      toggleEdit();

      if (record.Phone !== ValueObject.Phone)
        handleSave({ ...record, ...ValueObject });
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {phoneFields?.map((e) => {
          return (
            <Form.Item
              key={e}
              style={{
                margin: 0,
              }}
              name={e}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
                {
                  validator: validatePhone,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={() => save()}
                onBlur={() => (phoneFields.length == 1 ? save() : null)}
              />
            </Form.Item>
          );
        })}
      </>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
const copy = (text: any) => {
  navigator.clipboard.writeText(text);
};



function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}
const sendEducation = (record: any) => {
  sendEducationLinkApi(record)
    .then((res) => {
      toast("Education link sent successfully.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((e) => {
      toast("Failed to send Education link.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};
const sendJotForm = (record: any) => {
  sendJotFormLink(record)
    .then((res) => {
      toast("Case Form link sent successfully.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((e) => {
      toast("Failed to send Jot Form link", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

function itemRender(current: any, type: string, originalElement: any) {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
}
const AntTable = ({ isTableLoading, tableData }: any) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [TableData, setTableData] = useState<any>();
  const [state, dispatch] = useStateValue();
  const [copied, Setcopied] = useState(false);
  const [showCaseModal, setShowCaseModal] = useState<boolean>(false);
  const [showInvestigator, setShowInvestigator] = useState<boolean>(false);
  const [InvestigatorDetails, setInvestigatorDetails] = useState<any>();
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [newInvestigatorId, setNewInvestigatorID] = useState<any>();
  const [isTableDataLoading, setisTableDataLoading] = useState<boolean>(true);
  const [tableDataLength, setTableDataLength] = useState<number>();
  const [form] = Form.useForm();
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const managedInMdssID = (record: any) => {
    ManagedInMdssApi(record)
      .then((res) => {
        toast("Successfully changed to Managed in MDSS.", {
          position: "top-right",
          theme: "dark",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch({
          type: "SETREFRESH",
          refresh: !state.refresh,
        });
      })
      .catch((e) => {
        toast("Failed to send Jot Form link.", {
          position: "top-right",
          theme: "dark",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRow: any
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(selectedRow);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => {
      let checkboxProps: any = {};
      if (
        record.Form_Status === "Managed in MDSS" ||
        record.Form_Status === "MDSS Loaded"
      ) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };
  const isAnyFilter = () => {
    // if (state.mdssIdFilterValues.length + state.patientNameFilterValues.length + state.investigatorFilterValues.length === 3) {
    //     return
    // }
    let count = 0;
    let searchQuerry: {
      MDSS_Id?: Array<String>;
      Patient_id?: Array<String>;
      Investigator_Id?: Array<String>;
      Form_Sent_Date?: Array<String>;
    } = {
      MDSS_Id: [],

      Patient_id: [],

      Investigator_Id: [],

      Form_Sent_Date: [],
    };
    if (
      state.mdssIdFilterValues.length === 0 ||
      state.mdssIdFilterValues.includes("1")
    )
      delete searchQuerry["MDSS_Id"];
    else {
      searchQuerry["MDSS_Id"] = state.mdssIdFilterValues;
    }
    if (
      state.patientNameFilterValues.length === 0 ||
      state.patientNameFilterValues.includes("1")
    )
      delete searchQuerry["Patient_id"];
    else {
      searchQuerry["Patient_id"] = state.patientNameFilterValues;
    }
    if (
      state.investigatorFilterValues.length === 0 ||
      state.investigatorFilterValues.includes("1")
    )
      delete searchQuerry["Investigator_Id"];
    else {
      searchQuerry["Investigator_Id"] = state.investigatorFilterValues;
    }
    if (state.formDate.length === 0) delete searchQuerry["Form_Sent_Date"];
    else {
      searchQuerry["Form_Sent_Date"] = state.formDate;
    }

    dispatch({
      type: "SetSearchQuerry",
      searchQuerry: searchQuerry,
    });
  };
  // {
  //   ChangeInvestigator: newData;
  // }
  const changeInvestigator = (newId: any): void => {
    let changeRecord = [
      {
        MDSS_ID: currentRecord.MDSS_Id,
        Patient_ID: currentRecord.Patient_id,
        Changedby: state.currentUser.Investigator_email,
        Old_InvestigatorID: currentRecord.Investigator_Id,
        Jurisdiction_Old: currentRecord.Orginal_Jurisdiction,
        New_InvestigatorID: newId,
      },
    ];

    ChangeBulkInvestigator({ ChangeInvestigator: changeRecord }).then((res) => {
      toast("Successfully changed investigator.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: "SETREFRESH",
        refresh: !state.refresh,
      });
    });
  };
const getEncryptedToken=()=>{
  const ciphertext = CryptoJS.AES.encrypt(localStorage.getItem('token')||"", 'M!r@Cl$@5o7').toString();
  return btoa(ciphertext)
}
  useEffect(() => {
    GetFilters().then((res) => {
      let optns = res?.InvestigatorDetails?.map((a: any) => {
        return {
          ID: a.InvestigatorID,
          Value: `${a.Investigator_FirstName} ${a.Investigator_LastName}`,
          ...a,
        };
      });

      setInvestigatorDetails(optns);
    });

    setisTableDataLoading(isTableLoading);

    setTableData(tableData);
  
    // setTableData(dataSource);
  }, [isTableLoading, tableData]);
  // useEffect(() => {
  //   isAnyFilter();
  // }, [
  //   state.mdssIdFilterValues,
  //   state.patientNameFilterValues,
  //   state.investigatorFilterValues,
  //   state.formDate,
  // ]);
  useEffect(() => {
    if (state.reset == true) {
      setFilteredInfo({});
      setSortedInfo({});
    }
  }, [state.reset]);
  useEffect(() => {
    dispatch({
      type: "SETSELECTEDROWS",
      selectedRows: selectedRow,
    });
  }, [selectedRow]);
  useEffect(() => {
    setSelectedRow([]);
    setSelectedRowKeys([]);
  }, [state.removeSelected]);

  const handleSave = (row: any) => {
    const newData = [...TableData];
    const index = newData.findIndex(
      (item) =>
        row.Patient_id + row.Message_Status ===
        item.Patient_id + item.Message_Status
    );
    const item = newData[index];
    // console.log(item, row);
    let changeNum: any = {
      MDSS_ID: item.MDSS_Id,

      patientId: item.Patient_id,

      Old_MobilePhone: item.Phone,

      New_MobilePhone: row.Phone,

      UpdatedBy: state.currentUser.Investigator_email,
    };

    if (item.Form_Status === "Incomplete") {
      changeNum["Form_Status"] = "Incomplete";
    }

    changeNumber(changeNum).then((res) => {
      toast("Phone number updated.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
    newData.splice(index, 1, { ...item, ...row });

    setTableData(newData);
  };
  const Bcolumns = [
    {
      title: "Investigator Name",
      dataIndex: "Investigator_Name",
      key: "Investigator_Name",
      width: 170,
      fixed: "left" as "left",
      sorter: (a: any, b: any) =>
        a.Investigator_Name.localeCompare(b.Investigator_Name),
      sortOrder:
        sortedInfo.columnKey === "Investigator_Name" ? sortedInfo.order : null,
      render: (id: any, record: any) => {
        return (
          <div className="idCell">
            <div className="ellipsisText">
              <Tooltip title={id}>{_.startCase(id)}</Tooltip>
            </div>

            {record.Form_Status !== "MDSS Loaded" &&
            record.Form_Status !== "Managed in MDSS" &&
            (state.currentUser.IsAdmin === "yes" ||
              state.currentUser.IsSuperUser === "yes") ? (
              <span>
                {InvestigatorDetails?.length > 0 ? (
                  <FaExternalLinkAlt
                    onClick={(e) => {
                      setCurrentRecord(record);
                      setShowInvestigator(true);
                      e.stopPropagation();
                    }}
                    className="iconPoin actionIcon"
                  />
                ) : (
                  <FaSpinner className="spinner" />
                )}
              </span>
            ) : (
              <></>
            )}

            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Patient MDSS ID",
      fixed: "left" as "left",
      render: (id: any, record: any) => {
        const bgClass =
          record.Form_Status === "Managed in MDSS" ? "disabledRow" : "noting";
        return (
          <div className={`idCell`}>
            {id}
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);

                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
      width: 160,
      dataIndex: "MDSS_Id",
      key: "MDSS_Id",
      sorter: (a: any, b: any) => a.MDSS_Id - b.MDSS_Id,
      sortOrder: sortedInfo.columnKey === "MDSS_Id" ? sortedInfo.order : null,
    },
    {
      title: "Form Sent",
      width: 150,
      dataIndex: "Form_Sent_Date",
      key: "Form_Sent_Date",

      render: (id: any) => {
        return (
          <div className="idCell">
            {id.value}{" "}
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id.value);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
      sortOrder:
        sortedInfo.columnKey === "Form_Sent_Date" ? sortedInfo.order : null,
      sorter: (a: any, b: any) =>
        moment(a.Form_Sent_Date.value, "YYYY-MM-DD").valueOf() -
        moment(b.Form_Sent_Date.value, "YYYY-MM-DD").valueOf(),
    },
    {
      title: "Patient",
      dataIndex: "PatientName",
      key: "PatientName",
      width: 160,
      sorter: (a: any, b: any) => a.PatientName.localeCompare(b.PatientName),
      sortOrder:
        sortedInfo.columnKey === "PatientName" ? sortedInfo.order : null,
      render: (id: any, record: any) => {
        return (
          <div className="idCell">
            <div className="ellipsisText">
              <Tooltip title={id}>{_.startCase(id)}</Tooltip>
            </div>
            <span>
              <Popover
                placement="bottom"
                content={
                  <ul className="list">
                    <Popconfirm
                      placement="left"
                      title={
                        <span>
                          You are about to send <b>Education Link</b>
                        </span>
                      }
                      onConfirm={(e) => sendEducation(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Send Education Link
                        <span>
                          <FaExternalLinkAlt
                            className="iconEdit"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </span>
                      </li>
                    </Popconfirm>
                    <Popconfirm
                      placement="left"
                      title={
                        <span>
                          You are about to send <b>Case Form Link</b>
                        </span>
                      }
                      onConfirm={() => {
                        sendJotForm(record);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <li>
                        Send Case Form Link{" "}
                        <span>
                          <FaExternalLinkAlt
                            className="iconEdit"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </span>
                      </li>
                    </Popconfirm>

                    <li
                      onClick={() => {
                        console.log(record.Jot_Form,"record.Jot_Form")
                        if (record.Jot_Form.includes("/edit/")) {
                          window.open(`/edit/form?submissionID=${record.Jot_Form.split("/").pop().split("?")[0]}&token=${getEncryptedToken()}`, "_blank");
                          // navigate(`/edit/form?submissionID=${record.Jot_Form.split("/").pop().split("?")[0]}`);
                          //localStorage.getItem('token')
                        } else {
                          // window.open(`/verify&token=${getEncryptedToken()}`,"_blank")
                          window.open(record.Jot_Form, "_blank");
                          // navigate(record.Jot_Form);
                        }
                      }}
                    >
                      View/Edit Case Form
                      <span>
                        <FaExternalLinkAlt
                          className="iconEdit"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </span>
                    </li>
                  </ul>
                }
                overlayClassName={"ActionsCSS"}
                title="Actions"
                trigger="click"
              >
                <FaExternalLinkAlt
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="iconPoin actionIcon"
                />
              </Popover>
            </span>
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Jurisdiction",
      dataIndex: "Jurisdiction_Code",
      key: "Jurisdiction_Code",
      width: 120,
      sorter: (a: any, b: any) => a.Jurisdiction_Code - b.Jurisdiction_Code,
      sortOrder:
        sortedInfo.columnKey === "Jurisdiction_Code" ? sortedInfo.order : null,
      render: (id: any, record: any) => {
        return (
          <div className="idCell">
            {id}

            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Birthdate",
      dataIndex: "DOB",
      key: "DOB",
      width: 130,
      sortOrder: sortedInfo.columnKey === "DOB" ? sortedInfo.order : null,
      sorter: (a: any, b: any) =>
        moment(a?.DOB?.value, "YYYY-MM-DD ").valueOf() -
        moment(b?.DOB?.value, "YYYY-MM-DD ").valueOf(),
      render: (id: any) => {
        return (
          <div className="idCell">
            {id?.value}
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id?.value);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Address",
      width: 200,
      dataIndex: "Address",
      key: "Address",
      // sorter: (a: any, b: any) => a.Address?.localeCompare(b.Address),
      // sortOrder: sortedInfo.columnKey === "Address" ? sortedInfo.order : null,
      render: (id: any) => {
        return (
          <div className="idCell address">
            {id}
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      editable: "true",
      width: 170,

      render: (Phone: any, record: any) => {
        return (
          <>
            {Phone !== "" && Phone !== null && Phone !== "null" ? (
              <div
                className="idCell phoneNumber"
                // style={{
                //   cursor:
                //     record.Form_Status === "MDSS Loaded" ||
                //     record.Form_Status === "Managed in MDSS"
                //       ? "not-allowed"
                //       : "disabl",
                // }}
              >
                {isValidPhoneNumber(Phone, "US") && Phone.length <= 12 ? (
                  <div className="ellipsisPhone">
                    {formatPhoneNumber(Phone)}
                  </div>
                ) : (
                  <Tooltip title={Phone}>
                    <div className="ellipsisPhone">{Phone}</div>
                  </Tooltip>
                )}

                <span className="showOnHover">
                  <Tooltip
                    className="textTool"
                    placement="right"
                    mouseLeaveDelay={0.1}
                    title={copied ? "Copied to clipboard" : "Click to copy"}
                    onVisibleChange={(v) => {
                      setTimeout(() => {
                        if (!v) Setcopied(false);
                      }, 100);
                    }}
                  >
                    <FaRegCopy
                      style={{
                        color: copied ? "#00b2b9" : "#A1A1A1",
                      }}
                      className="iconCopy"
                      onClick={(e) => {
                        Setcopied(!copied);
                        copy(Phone);
                        e.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </span>
              </div>
            ) : (
              <span
                className="phoneNumber"
                // style={{
                //   cursor:
                //     record.Form_Status === "MDSS Loaded" ||
                //     record.Form_Status === "Managed in MDSS"
                //       ? "not-allowed"
                //       : "disabl",
                // }}
              >
                --
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Case Status",
      dataIndex: "Form_Status",
      key: "Form_Status",
      width: 165,
      filterIcon: <FaFilter />,
      filters: [
        {
          text: "Incomplete",
          value: "Incompleted",
        },
        {
          text: "Patient Sub",
          value: "Patient Sub",
        },
        {
          text: "Invest Sub",
          value: "Invest Sub",
        },
        {
          text: "MDSS Loaded",
          value: "MDSS Loaded",
        },
        {
          text: "Auto-Transmit",
          value: "Auto Transmit",
        },
        {
          text: "Managed in MDSS",
          value: "Managed in MDSS",
        },
      ],
      filteredValue: filteredInfo.Form_Status || null,
      onFilter: (value: any, record: any) =>
        record?.Form_Status?.indexOf(value) === 0,
      render: (id: any, record: any) => {
        let color;
        switch (id) {
          case "Managed in MDSS":
            color = "#00b2b9";
            break;
          case "Patient Sub":
            color = "#fdb933";
            break;
          case "Invest Sub":
            color = "#34A162";
            break;
          case "Incompleted":
            color = "#dc5e5e";
            break;
          default:
            // console.log(id);
            color = "black";
            break;
        }
        return (
          <div className="idCell" style={{ color: color }}>
            {id === "Incompleted" ? "Incomplete" : id}
            {record.Form_Status === "Incompleted" &&
            (state.currentUser.IsAdmin === "yes" ||
              state.currentUser.IsSuperUser === "yes") ? (
              <span>
                <FaExternalLinkAlt
                  onClick={(e) => {
                    setCurrentRecord(record);
                    setShowCaseModal(true);
                    e.stopPropagation();
                  }}
                  className="iconPoin actionIcon"
                />
              </span>
            ) : (
              <></>
            )}

            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Message Status",
      dataIndex: "Message_Status",
      key: "Message_Status",
      width: 160,
      filterIcon: <FaFilter />,
      filteredValue: filteredInfo.Message_Status || null,
      filters: [
        {
          text: "Delivered",
          value: "Delivered",
        },
        {
          text: "Failed",
          value: "Failed",
        },
      ],
      onFilter: (value: any, record: any) =>
        record.Message_Status.indexOf(value) === 0,

      render: (id: any) => {
        let color;
        switch (id) {
          case "Delivered":
            color = "#00b2b9";
            break;
          case "Failed":
            color = "#dc5e5e";
            break;
          default:
            color = "black";
            break;
        }
        return (
          <div className="idCell" style={{ color: color }}>
            {id}
            <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                mouseLeaveDelay={0.1}
                title={copied ? "Copied to clipboard" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Case Type",
      width: 140,
      dataIndex: "CaseStatus",
      key: "CaseStatus",
      filterIcon: <FaFilter />,
      filters: [
        {
          text: "Confirmed",
          value: "CONFIRMED",
        },
        {
          text: "Probable",
          value: "PROBABLE",
        },
        {
          text: "Unknown",
          value: "Unknown",
        },
      ],
      filteredValue: filteredInfo.CaseStatus || null,
      onFilter: (value: any, record: any) =>
        record?.CaseStatus?.indexOf(value) === 0,
      render: (id: any) => {
        return (
          <div className="idCell">
            {_.upperCase(id)}
            {/* <span className="showOnHover">
              <Tooltip
                className="textTool"
                placement="right"
                // mouseLeaveDelay={0.1}
                title={copied ? "Copied" : "Click to copy"}
                onVisibleChange={(v) => {
                  setTimeout(() => {
                    if (!v) Setcopied(false);
                  }, 100);
                }}
              >
                <FaRegCopy
                  style={{
                    color: copied ? "#00b2b9" : "#A1A1A1",
                  }}
                  className="iconCopy"
                  onClick={(e) => {
                    Setcopied(!copied);
                    copy(id);
                    e.stopPropagation();
                  }}
                />
              </Tooltip>
            </span> */}
          </div>
        );
      },
    },
  ];
  const columns = Bcolumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });
  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  const handleOk = () => {
    setShowCaseModal(false);
    setShowInvestigator(false);
  };
  const y = 900;
  const [vt, setComponent] = useVT(() => ({

    scroll: {

      y

    }

   }),

   [y]);
  setComponent({
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  });
  return (
    <div className="AntTable">
      {/* <Editable/> */}
      <Modal
        title="Change Case Status"
        centered
        visible={showCaseModal}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button
            onClick={() => {
              managedInMdssID(currentRecord);
              handleOk();
            }}
            className="tabButtons"
          >
            OK
          </Button>,
        ]}
      >
        <span>
          *Managed in MDSS will be changed if current status is Incomplete or
          Inprocess.{" "}
        </span>
        <Select<string | number, { value: string; children: string }>
          style={{ width: "100%" }}
          placeholder="Select case status"
          optionFilterProp="children"
          value={"MDSSID"}
          disabled
          // onChange={(v) => }
          // onSearch={(v) => }
          // filterOption={(input, option) =>
          //   option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
        >
          <Option value="MDSSID">Managed in MDSS ID</Option>
        </Select>
      </Modal>
      <Modal
        title={"Change Investigator"}
        centered
        visible={showInvestigator}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button
            onClick={() => {
              changeInvestigator(newInvestigatorId);
              handleOk();
            }}
            className="tabButtons"
          >
            OK
          </Button>,
        ]}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={(v) => setNewInvestigatorID(v)}
          onSearch={(v) => console.log(`selected ${v}`)}
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {InvestigatorDetails?.length > 0 &&
            InvestigatorDetails?.map((investigator: any, index: any) => {
              if (
                currentRecord?.Jurisdiction_Code ===
                  investigator?.Jurisdiction_Code ||
                investigator?.IsAdmin === "yes" ||
                investigator?.IsSuperUser === "yes"
              )
                return (
                  <Option key={investigator.ID} value={investigator.ID}>
                    {investigator.Value}
                  </Option>
                );
            })}
        </Select>
      </Modal>
      <Form form={form} component={false}>
        <Table
          components={vt}
          columns={columns}
          dataSource={TableData}
          bordered
          rowClassName={(record, index) =>
            record.Form_Status === "MDSS Loaded" ||
            record.Form_Status === "Managed in MDSS"
              ? "disabledRow"
              : "noting"
          }
          onChange={(pagi, filter, sortres, extra) => {
            let empty: any = [];
            setSelectedRow(empty);
            setSelectedRowKeys([]);

            setFilteredInfo(filter);
            setSortedInfo(sortres as SorterResult<any>);
            dispatch({
              type: "SETRESET",
              reset: false,
            });
            setTableDataLength(extra.currentDataSource.length);
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={(record) => record.Patient_id + record.Message_Status}
          loading={{
            indicator: (
              <Loader name={"audio"} height={40} width={40} color={"#fdb933"} />
            ),
            spinning: isTableDataLoading,
          }}
          pagination={false}
          // pagination={{
          //   total: tableDataLength,
          //   className: "pagination",
          //   showSizeChanger: false,
          //   showTotal: (total, range) =>
          //     `Displaying ${range[0]}-${range[1]} of ${total} items`,
          //   defaultPageSize: 10,
          //   defaultCurrent: 1,
          //   size: "small",
          //   itemRender: itemRender,
          // }}
          scroll={{ x: 1700, y: "calc(88vh - 15em)" }}
        />
      </Form>

      {/* <Table
        components={vt}
        bordered
        rowKey={(record) => record.Patient_id + record.Message_Status}
        columns={columns}
        dataSource={TableData}
        rowClassName="editable-row"
        scroll={{ x: 1700, y: "calc(78vh - 15em)" }} // !!!!!!!!!!!!!!!
        pagination={false}
      /> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        toastClassName="fontMon"
        pauseOnHover
      />
    </div>
  );
};

export default AntTable;
